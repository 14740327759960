<template>
	<v-sheet v-if="incentiveOnly">
		<project-intro
			:id="project.id"
			intro="This area can be used to import incentive records"
		></project-intro>
		<v-card flat color="lightest">
			<navigation :id="id"></navigation>
			<v-stepper flat v-model="step" class="transparent">
				<v-stepper-header flat outlined>
					<v-stepper-step :complete="step > 0" step="0">
						Reference
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step :complete="step > 1" step="1">
						Upload
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step :complete="step > 2" step="2">
						Validate
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step :complete="step > 3" step="3">
						Confirm
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step step="4">
						Complete
					</v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="0">
						<v-card flat class="mb-4">
							<v-card-text>
								<p>
									Please enter the Statement of Work reference number
									for your incentive payments
								</p>
								<v-text-field
									label="Your Reference"
									v-model="sow"
								></v-text-field>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									@click="step = 1"
									:disabled="!sow"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="1">
						<v-card flat class="mb-4">
							<v-card-text>
								<v-alert text>Statement of work: {{ sow }}</v-alert>
								<p>
									Upload a CSV file with the following fields: email,
									first_name, last_name, incentive amount
								</p>
								<p>
									Download an example document
									<a
										href="https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/uploads%2Fincentives_import.csv?alt=media&token=0b918a26-d1fe-4f65-9da0-6ee5557298d8"
										>here</a
									>
								</p>
								<p>Imports are limited to 10 records at a time.</p>
								<v-file-input
									label="Upload CSV"
									accept="text/csv"
									:rules="rules"
									v-model="file"
									clearable
									@click:clear="clear"
								></v-file-input>
							</v-card-text>
							<v-card-actions>
								<v-btn text @click="clear">
									Cancel
								</v-btn>
								<v-spacer></v-spacer>
								<v-btn class="mr-1" color="secondary" @click="step = 0">
									Back
								</v-btn>
								<v-btn
									color="primary"
									@click="step = 2"
									:disabled="!file"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>

					<v-stepper-content step="2">
						<v-card flat>
							<v-card-text>
								<v-alert text>Statement of work: {{ sow }}</v-alert>
								<v-row>
									<v-col class="subtitle" cols="3">
										Email
									</v-col>
									<v-col class="subtitle" cols="2">
										First Name
									</v-col>
									<v-col class="subtitle" cols="2">
										Last Name
									</v-col>
									<v-col class="subtitle" cols="2">
										Amount
									</v-col>
									<v-col class="subtitle" cols="2">
										Amazon
									</v-col>
								</v-row>
								<contents
									v-for="(item, i) in items"
									@input="(k, v) => update(i, k, v)"
									@delete="remove(i)"
									:key="i"
									v-bind="item"
								></contents>
							</v-card-text>
							<v-card-actions>
								<v-btn text @click="clear">
									Cancel
								</v-btn>

								<v-spacer></v-spacer>
								<v-btn class="mr-1" color="secondary" @click="step = 1">
									Back
								</v-btn>
								<v-btn
									color="primary"
									@click="step = 3"
									:disabled="!countValid"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="3">
						<v-card flat class="mb-4">
							<v-card-text>
								<v-alert text>Statement of work: {{ sow }}</v-alert>
								<v-form v-model="confForm">
									<p>Please confirm the following for all participants: </p>
									<v-checkbox
										label="Participant's research activity is complete"
										required
										:rules="notEmpty"
									></v-checkbox>
									<v-checkbox
										label="Participant has provided consent for their information to be share with Meisterworks"
										required
										:rules="notEmpty"
									></v-checkbox>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-btn text @click="clear">
									Cancel
								</v-btn>

								<v-spacer></v-spacer>
								<v-btn class="mr-1" color="secondary" @click="step = 2">
									Back
								</v-btn>
								<v-btn
									color="primary"
									@click="step = 4"
									:disabled="!confForm"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>

					<v-stepper-content step="4">
						<v-card flat class="mb-4">
							<v-card-text>
								<v-alert text>Statement of work: {{ sow }}</v-alert>
								<p v-if="countValid">
									Inserting records for {{ countValid }} rows
								</p>
								<div v-if="invalidRecords.length">
									<p>
										The following records failed validation and will
										not be inserted:
									</p>
									<ul>
										<li v-for="(item, i) in invalidRecords" :key="i">
											{{ item.email }}
										</li>
									</ul>
								</div>
							</v-card-text>
							<v-card-actions>
								<v-btn text @click="clear">
									Cancel
								</v-btn>

								<v-spacer></v-spacer>
								<v-btn class="mr-1" color="secondary" @click="step = 3">
									Back
								</v-btn>
								<v-btn color="primary" @click="submit">
									Submit
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-card>
	</v-sheet>
</template>

<style lang="less">
.v-stepper.transparent {
	background: transparent;
	.v-stepper__header {
		box-shadow: none;
	}
}
</style>

<script type="text/javascript">
import ProjectIntro from "@c/project/ProjectIntro";
import Contents from "@c/incentives/ImportContents";
import Navigation from "@c/incentives/Navigation";
import Vue from "vue";

export default {
	name: "ImportIncentives",
	components: {
		Contents,
		Navigation,
		ProjectIntro,
	},
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			step: 0,
			sow: null,
			file: null,
			confForm: null,
			items: [],
			notEmpty: [(v) => !!v || "Value is required"],
			rules: [
				(value) =>
					!value || value.type == "text/csv" || "Please select a csv file",
			],
			headers: ["email", "first_name", "last_name", "amount", "amazon"],
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		countValid() {
			return this.items.filter((a) => a.valid).length;
		},
		invalidRecords() {
			return this.items.filter((i) => !i.valid);
		},
		project() {
			return this.$store.state.projects.data[this.id];
		},
		incentiveOnly() {
			return this.project && this.project.incentive_only;
		},
	},
	watch: {
		file() {
			this.readContents();
		},
	},
	methods: {
		remove(i) {
			this.items.splice(i, 1);
		},
		update(i, k, v) {
			let item = this.items[i];
			item[k] = v;
			Vue.set(this.items, i, item);
		},
		clear() {
			this.step = 0;
			this.sow = null;
			this.items = [];
			this.file = null;
		},
		isHeader(row) {
			row = row.map((r) => r.trim());
			if (JSON.stringify(row) == JSON.stringify(this.headers)) {
				return true;
			}
			return false;
		},
		readContents() {
			const self = this;
			let file = self.file;
			const reader = new FileReader();
			reader.addEventListener(
				"load",
				() => {
					self.items = self.makeItems(reader.result);
				},
				false
			);

			if (file) {
				reader.readAsText(file);
			}
		},
		makeItems(raw) {
			var self = this;
			raw = raw.split("\n");
			raw.splice(11);
			raw = raw.map((r) => r.split(","));
			if (self.isHeader(raw[0])) {
				raw.shift();
			}
			let items = raw.map((line) => {
				var row = {};
				self.headers.forEach((h, i) => {
					row[h] = line[i];
				});
				row.valid = null;
				row.amazon = row.amazon ? row.amazon.trim() : false;
				row.amazon = row.amazon ? true : false;
				row.amount = parseInt(row.amount) || 0;
				return row;
			});
			return items;
		},

		submit() {
			const self = this;
			let items = self.items.filter((i) => i.valid);
			let requests = items.map((item) => {
				item.status = "new";
				item.project = self.id;
				item.sow = self.sow;
				return new Promise((resolve) => {
					self.$store
						.dispatch("incentiveSubmissions/insert", item)
						.then(() => resolve());
				});
			});

			Promise.all(requests)
				.then(() => {
					self.$root.$emit("showToast", "Submission complete - please check the View page for details of submitted participants");
					self.clear();
				})
				.catch(() => {
					self.$root.$emit("showToast", "Error Saving", true);
				});
		},
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
	},
};
//
</script>
