<template>
	<v-form ref="form" v-model="valid">
		<v-row>
			<v-col>
				<v-text-field
					dense
					:rules="emailRules"
					:value="email"
					@input="(v) => update('email', v)"
				>
				</v-text-field>
			</v-col>
			<v-col>
				<v-text-field
					dense
					:rules="notEmpty"
					:value="first_name"
					@input="(v) => update('first_name', v)"
				>
				</v-text-field>
			</v-col>
			<v-col>
				<v-text-field
					dense
					:rules="notEmpty"
					:value="last_name"
					@input="(v) => update('last_name', v)"
				>
				</v-text-field>
			</v-col>
			<v-col cols="2">
				<v-text-field
					dense
					:rules="notZero"
					type="number"
					:value="amount"
					@input="(v) => update('amount', v)"
				>
				</v-text-field>
			</v-col>
			<v-col cols="2" class="pt-0"> 
				<v-checkbox hide-details :input-value="amazon" @input="(v) => update('amazon', v)">
				</v-checkbox>
			</v-col>
			<v-col cols="1">
				<v-btn small icon @click="$emit('delete')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
	export default {
		name: "ImportContents",
		props: {
			amount: {type: [Number, String, NaN]},
			email: {type: String},
			last_name: {type: String},
			first_name: {type: String},
			amazon: [String, Boolean]
		},
		data: () => {
			return {
				valid: null,
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
				],
				notEmpty: [(v) => !!v || "Value is required"],
				notZero: [(v) => v > 0 || "Amount must be greater than zero"]
			};
		},
		methods: {
			update(k, v) {
				this.$emit("input", k, v);
			}
		},
		watch: {
			valid: {
				immediate: true,
				handler(v) {
					this.$emit("input", "valid", v);
				}
			}
		},
		mounted() {
			this.$refs.form.validate();
		}
	};
</script>
